import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import useSWR from 'swr';
import {fetcherSimple} from 'utils/api';
import ContentWrapper from 'components/ui/content-wrapper';
import GobbyLogo from 'assets/img/logos/gobby-logo-FIN_Stacked-BL-wTag-.png';
import GobbyVideo from 'assets/video/gobby_explain.mp4';
import { ResponseWrapper } from 'components/ui/response-wrapper';
import Image from 'components/image';
import { Alert, Button, Checkbox, Typography, Tooltip, List, ListItem } from '@material-tailwind/react';


export default function LandingSurvey() {
    const {surveyNanoid} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    // auth session (means preview page from survey builder)
    const { data: session, error: sessionError } = useSWR(() => ['/v2/auth/session', 'GET'],  ([path, method]) => fetcherSimple(path, method));
    const {data: auth, error: authError} = useSWR([searchParams.has('key') ? `/v2/response/auth?key=${searchParams.get('key')}` : null, 'GET'], ([path, method]) => fetcherSimple(path, method));
    const {data: anon, error: anonError} = useSWR([!searchParams.has('key') ? `/v2/response/anon/${surveyNanoid}` : null, 'GET'], ([path, method]) => fetcherSimple(path, method));
    const {data: status, error: statusError} = useSWR([(auth || anon) ? `/v2/response/status` : null, 'GET'], ([path, method]) => fetcherSimple(path, method));
    const [enableStart, setEnableStart] = useState(false);
    const [buttonText, setButtonText] = useState('Finished');
    const [gobbyTermsChecked, setGobbyTermsChecked] = useState(false);
    const [providerTermsChecked, setProviderTermsChecked] = useState(false);
    const [logoAvailable, setLogoAvailable] = useState(true);
    
    useEffect(() => {
        if (session) {
            setEnableStart(true);
            setButtonText('Test Survey');
        } else if (status && status?.survey?.state === 'archive') {
            setButtonText('Finished');
        } else if (status && status?.survey?.state === 'active') {
            if (status?.finished) {
                setButtonText('Finished');
            } else if (status?.started) {
                setEnableStart(true);
                setButtonText('Continue');
            } else {
                setEnableStart(true);
                setButtonText('Start');
            }
        } else if (status && status?.survey?.state === 'draft') {
            setButtonText('Disabled');
        }
    }, [status, session]);

    return (
        <ResponseWrapper>
            <ContentWrapper>
                <>
                {((statusError) &&
                    <div>
                        Error loading survey status!
                    </div>
                )}
                {((authError || anonError) &&
                    <div className="flex flex-col md:max-w-[1000px] mx-auto mt-4 bg-white rounded-xl shadow-lg sm:my-6 my-4">
                        <div className="flex flex-row m-4 items-center justify-between">
                            <div className="flex-auto">
                                
                            </div>
                            <div className="shrink-0">
                            <a href={"https://gobby.io"}>
                                <Image
                                    className="md:w-28 w-14"
                                    src={GobbyLogo}
                                    rounded={false}
                                    raised={false}
                                    alt="Gobby(R) Logo Image"/>
                            </a>
                            </div>
                        </div>
                        <div className="py-4 border-t-2 border-gray-700">
                        <Alert color="red" className="mt-1 rounded-xl py-1 pl-5">
                            {(searchParams.has('key') ? authError.toString() : anonError.toString())}
                        </Alert>
                        </div>
                    </div>
                )}
                {(status &&
                    <div className="flex flex-col md:max-w-[1000px] sm:mx-auto mx-1 bg-white rounded-xl shadow-lg sm:my-6">
                        <div className="flex flex-row sm:m-4 items-center justify-between">
                            <div className="flex-auto">
                                {( logoAvailable &&
                                <Image alt="survey logo"
                                    src={process.env.REACT_APP_API_SERVER + '/v2/response/logo'}
                                    className="md:w-28 w-14"
                                    onError={()=>{setLogoAvailable(false)}}
                                />
                                )}
                            </div>
                            <div className="shrink-0">
                            <a href={"https://gobby.io"}>
                                <Image
                                    className="md:w-28 w-14"
                                    src={GobbyLogo}
                                    rounded={false}
                                    raised={false}
                                    alt="Gobby(R) Logo Image"/>
                            </a>
                            </div>
                        </div>
                        <div className="md:px-10 px-2">
                            <div className="text-center sm:mt-4 mt-2 w-fit px-10 font-display md:text-2xl text-lg mx-auto py-2 rounded-2xl bg-gray-300 shadow mb-4">
                                {status.survey.name || 'Supply a good survey name'}
                            </div>

                            {(session &&
                                <div className='align-center md:mx-4 m-2 font-body md:font-bold  
                                                text-blue-gray-900 md:p-4 md:py-6 p-2 my-4 
                                                xl:mx-20 lg:mx-10 md:mx-4 rounded-xl bg-gray-300 shadow'>
                                                    <Typography className='pb-6 font-display text-lg text-center'>You are viewing a<br/>'PREVIEW MODE'</Typography>
                                                    This view allows you to check the survey design and question settings from a respondent perspective.
                                                    Survey functions are limited e.g., cannot save or search for matched responses, as with a live survey.
                                                    If you need to test a survey with full functionality:
                                                    <Typography className='mt-2 pl-6 font-body'>
                                                        &#9733; Launch the survey when you're happy with it.<br/>
                                                        &#9733; Send it to testers and ask for their feedback.<br/>
                                                        &#9733; Clone the survey and make the necessary changes.<br/>
                                                        &#9733; Repeat until you have a final version of the survey.<br/>
                                                    </Typography>
                                </div>
                             )}

                            {((status.finished === false && status?.survey?.start_text) &&
                                <>
                                <div className='font-body sm:text-lg text-md text-center sm:mt-4 m-2 sm:mx-6'>
                                    {status?.survey?.start_text?.split('\n').map((text, index) => (
                                        <React.Fragment key={index}>
                                            {text || ''}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                                </>
                            )}
                            {(((status.finished || (session && searchParams.has('previewThankYou'))) && status?.survey?.end_text) &&
                                <div  className='font-body text-center text-lg m-6 pt-6'>
                                    {status?.survey?.end_text?.split('\n').map((text, index) => (
                                        <React.Fragment key={index}>
                                            {text || ''}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                            {((status.survey.state === 'active' && status.started === false) &&
                                <div className="">
                                    <div className="flex flex-col items-center font-body">
                                        <div className="sm:text-2xl text-lg font-display text-center mt-4 sm:mt-10">
                                            Ready to be Gobby®?
                                        </div>
                                        <div className='sm:text-lg text-sm'>
                                        <Checkbox color="amber" onChange={()=>{setGobbyTermsChecked(gobbyTermsChecked=>!gobbyTermsChecked)}}/>
                                        <>
                                            I have read & accept the Gobby&nbsp;
                                            <a style={{color: "revert"}} href={'https://gobby.io/terms-and-conditions/'} target="_blank" 
                                                rel="noopener noreferrer">Terms & Conditions&nbsp;</a>
                                            and&nbsp;
                                            <a style={{color: "revert"}} href='https://gobby.io/privacy-policy/' target="_blank" 
                                                rel="noopener noreferrer">Privacy Policy</a>
                                        </>
                                        <>
                                        {( status.survey.policy_url &&
                                        <>
                                        <br /><Checkbox color="amber" onChange={()=>{setProviderTermsChecked(providerTermsChecked=>!providerTermsChecked)}}/>
                                        I have read & accept the survey owner's&nbsp;
                                        <a style={{color: "revert"}} href={status.survey.policy_url || 'https://gobby.io/privacy-policy/'} target="_blank" 
                                            rel="noopener noreferrer">Customer Policies&nbsp;</a>. 
                                        </>
                                        )}
                                        </>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='text-center'>
                                {( (enableStart || session) &&
                                    <Button 
                                        className='rounded-xl px-6 py-2 m-4 
                                                    bg-black text-white hover:bg-amber-500 
                                                    hover:text-black font-body text-lg normal-case' 
                                        size="lg" 
                                        type="submit" 
                                        variant="filled"
                                        onClick={()=>(status.finished ? window.open('https://gobby.io/', '_self') : navigate(`/respond/${surveyNanoid}/answer`))}
                                            disabled={!(enableStart && (status.started || (status.survey.policy_url ? gobbyTermsChecked && providerTermsChecked : gobbyTermsChecked) || session))}>
                                        {buttonText}
                                    </Button>
                                )}
                                {((status.finished || (session && searchParams.has('previewThankYou'))) &&
                                    <Button 
                                        className='rounded-xl px-6 py-2 ml-4 
                                        hover:text-white hover:bg-black 
                                        border border-gray-500 font-body font-thin normal-case' 
                                        size="lg" 
                                        type="submit"
                                        variant="gradient"
                                        color="white"
                                        onClick={()=>window.open('https://gobby.io/#contact', '_blank')}
                                        disabled={!((status.finished || session))}>
                                        Contact Us
                                    </Button>
                                )}
                            </div>
                            
                            <div className="my-4 sm:mx-10 p-2 z-90">
                                    <Typography className="text-center my-2 font-display md:text-2xl text-lg">
                                        View the following video to see how Gobby works.
                                    </Typography>
                                    <Tooltip
                                        className="font-body bg-amber-700 py-2 px-10 rounded-xl mt-16" 
                                        animate={{
                                        mount: { scale: 1, y: 0 },
                                        unmount: { scale: 1, y: 25 },
                                        }}
                                        content="View this video to see how Gobby works">
                                    <video className="w-full max-w-[600px] h-full m-auto rounded-2xl border border-black" controls>
                                    <source src={GobbyVideo} type="video/mp4" alt="View this video to see how Gobby works" />
                                    Your browser does not support the playing of video via the html5 video tag.
                                    </video>
                                    </Tooltip>
                            </div>          

                            {((status.survey.state === 'active' && status.started === false) &&
                            <>
                            <div className='font-body text-left sm:mt-6 sm:mx-20 sm:text-xl text-lg'>                                 
                                    <Typography className='font-body mt-4'>
                                    How are Gobby® surveys different?
                                    </Typography>
                                    <Typography className='font-body mx-8 mt-2'>
                                    1.	YOU create the answers, because you're brilliant.
                                    </Typography>
                                    <Typography className='font-body mx-8 mt-1'>
                                    2.	YOU can see and vote for answers from other people with shared experiences.
                                    </Typography>
                                    <Typography className='font-body mx-8 mt-1'>
                                    3.	YOU have the power to make a difference.
                                    </Typography>
                                    <Typography className='font-body mt-4'>
                                    Answers are moderated, please be respectful at all times.
                                    </Typography>
                                </div>
                                </>
                            )}
                            

                        </div>
                    </div>
                )}
                </>
            </ContentWrapper>
        </ResponseWrapper>
    );
}
