import React, { useEffect, useState } from 'react';
import { Button, Option, Select, TabPanel, 
    Collapse, Typography, Card, CardHeader, CardBody} from '@material-tailwind/react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { fetcherFile} from 'utils/api';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';
import { ResponsiveSunburst } from '@nivo/sunburst';

export default function ReportsTab({ tabValue, survey, surveyId, rawSurveyReport, themesSurveyReport, questionResponsesSentiment,
    keyphrasesSentiment, respondentsResponsesSentiment,questionTotalSentimentData, keyphraseBySentiment, sentimentByKeyphrase
 }) {
    //Collapse Controls
    const [collapseMainInfo, setCollapseMainInfo] = useState(false);
    const [collapseGenCompletion, setCollapseGenCompletion] = useState(false);
    const [collapseGenTimeSpent, setCollapseGenTimeSpent] = useState(false);
    const [collapseGenNumResponses, setCollapseGenNumResponses] = useState(false);
    const [collapseGenNumResponsesEach, setCollapseGenNumResponsesEach] = useState(false);
    const [collapseThemesNumResponses, setCollapseThemesNumResponses] = useState(false);
    const [collapseThemesNumAnswers, setCollapseThemesNumAnswers] = useState(false);
    const [collapseThemesNumResponsesQ, setCollapseThemesNumResponsesQ] = useState(false);

    // Charts Charts
    const [pieCompletionData, setPieCompletionData] = useState([]);
    const [pieTimeData, setPieTimeData] = useState([]);
    const [pieDemographicData, setPieDemographicData] = useState([]);
    const [sunburstDemographicCompletionData, setSunburstDemographicCompletionData] = useState({});
    const [barResponseAmountData, setBarResponseAmountData] = useState([]);
    const [barResponseAmountHeight, setBarResponseAmountHeight] = useState(20);
    const [barQuestionsRespondedData, setBarQuestionsRespondedData] = useState([]);
    const [barQuestionsRespondedHeight, setBarQuestionsRespondedHeight] = useState(20);
    const [barThemesAnswersData, setBarThemesAnswersData] = useState([]);
    const [barThemesAnswersHeight, setBarThemesAnswersHeight] = useState(20);
    const [barThemesAnswersLeftMargin, setBarThemesAnswersLeftMargin] = useState(80);
    const [barThemesResponsesData, setBarThemesResponsesData] = useState([]);
    const [barThemesResponsesHeight, setBarThemesResponsesHeight] = useState(20);
    const [barThemesResponsesLeftMargin, setBarThemesResponsesLeftMargin] = useState(80);
    const [barThemesQuestionsData, setBarThemesQuestionsData] = useState([]);
    const [barThemesQuestionsDataKeys, setBarThemesQuestionsDataKeys] = useState([]);
    const [barThemesQuestionsHeight, setBarThemesQuestionsHeight] = useState(20);
    // Insight Ranking Data
    const [completionData, setCompletionData] = useState();
    const [topThemesInsight, setTopThemesInsight] = useState([]);
    const [demographicQShown, setDemographicQShown] = useState(0);

    useEffect(() => {
        const rawData = structuredClone(rawSurveyReport);
        if (rawData?.report?.[0]?.[0]) {
            // Responses per Q
            const surveyReport = rawData.report.sort((a, b) =>  a.question_position - b.question_position);
            const surveyReportProcessed = surveyReport.flat().map(q => {
                const question = structuredClone(q);
                const responses = question.responses;
                let processedResponses = [];
                responses.forEach((answer) => {
                    const responseArrayIndex = processedResponses.findIndex(r => r?.Response_ID === answer.Response_ID);
                    if (responseArrayIndex > -1) {
                        processedResponses[responseArrayIndex].themes.push({
                            theme_id: answer.Response_theme_ID,
                            theme_name: answer.Response_theme
                        })
                    } else {
                        const newIndex = processedResponses.push({
                            Recipient_UID_Survey_submission_timestamp: answer.Recipient_UID_Survey_submission_timestamp,
                            Answer_text: answer.Answer_text,
                            Recipient_UID_Creation_timestamp: answer.Recipient_UID_Creation_timestamp,
                            Answer_timestamp: answer.Answer_timestamp,
                            Answer_Type: answer.Answer_Type,
                            Is_Hidden: answer.Is_Hidden,
                            Response_ID: answer.Response_ID,
                            Recipient_UID: answer.Recipient_UID,
                            Answer_UID: answer.Answer_UID,
                            Answer_counter: answer.Answer_counter,
                            themes: []
                        });
                        if (answer.Response_theme_ID) {
                            processedResponses[newIndex-1].themes = [{
                                theme_id: answer.Response_theme_ID,
                                theme_name: answer.Response_theme
                            }]
                        }
                    }
                });
                question.responses = processedResponses;
                return question;
            });

            const barData = surveyReportProcessed.map(q => {
                const obj = {};
                obj.question_position = q.Question_Position;
                obj.question_name = q.Question;
                obj.responses = q.responses?.length;
                return obj;
            });
            const barDataSorted = barData.sort((a, b) =>  a.question_position - b.question_position);
            let barDataFormatted = barDataSorted;
            if (barDataFormatted) {
                setBarQuestionsRespondedData(barDataFormatted);
                setBarQuestionsRespondedHeight(barDataFormatted * 5);
            }

            // survey completion data
            // Respondents & their responses by Q
            const allResponsesOnly = surveyReportProcessed.map((q) => {
                return q.responses;
            }).flat();
            let allRespondentsData = {
                respondents: [],
                total_respondents: 0,
                respondents_finished: 0,
            };
            allResponsesOnly.forEach((answer) => {
                const respondentId = answer.Recipient_UID;
                const respondentArrayIndex = allRespondentsData.respondents.findIndex(t => t?.respondent_id === respondentId);
                if (respondentArrayIndex > -1) {
                    allRespondentsData.respondents[respondentArrayIndex].responses.push({
                        response_id: answer.Response_ID,
                        response_text: answer.Answer_text
                    });
                    allRespondentsData.respondents[respondentArrayIndex].responses_count++;
                } else {
                    allRespondentsData.respondents.push({
                        respondent_id: respondentId,
                        survey_start_time: answer.Recipient_UID_Creation_timestamp,
                        survey_completion_time: answer.Recipient_UID_Survey_submission_timestamp,
                        responses_count: 1,
                        responses: [{
                            response_id: answer.Response_ID,
                            response_text: answer.Answer_text
                        }]
                    });
                    allRespondentsData.total_respondents++;
                    if (answer.Recipient_UID_Survey_submission_timestamp) {
                        allRespondentsData.respondents_finished++;
                    }
                }
            });

            // Set Completion Pie Chart
            if (allRespondentsData.respondents.length) {
                setCompletionData(allRespondentsData);
                let surveyCompletionTimeAverageFinished = []; 
                let surveyCompletionTimeAverageNotFinished = [];
                allRespondentsData.respondents.forEach(respondent => {
                    if (respondent?.survey_completion_time) {
                        surveyCompletionTimeAverageFinished.push(
                            dayjs.duration(dayjs(respondent.survey_completion_time, 'YYYY-MM-DD HH:mm:ss').diff(dayjs(respondent.survey_start_time, 'YYYY-MM-DD HH:mm:ss'))).asMilliseconds()
                        );
                    } else {
                        const surveyLeft = (survey.state === 'finished' ? dayjs(survey.end_date)?.format('YYYY-MM-DDTHH:mm') : dayjs());
                        surveyCompletionTimeAverageNotFinished.push(
                            dayjs.duration(dayjs(surveyLeft).diff(dayjs(respondent.survey_start_time, 'YYYY-MM-DD HH:mm:ss'))).asMilliseconds()
                        );
                    }

                });
                const respondentsCompletionArray = [
                    {
                        id: 'Completed',
                        label: 'Completed',
                        value: allRespondentsData.respondents_finished
                    },
                    {
                        id: 'Unfinished',
                        label: 'Unfinished',
                        value: (allRespondentsData.total_respondents - allRespondentsData.respondents_finished)
                    }
                ];
                const timeAverage = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
                const respondentsTimeArray = [
                    {
                        id: 'Completed',
                        label: 'Completed',
                        value: timeAverage(surveyCompletionTimeAverageFinished)
                    },
                    {
                        id: 'Unfinished',
                        label: 'Unfinished',
                        value: timeAverage(surveyCompletionTimeAverageNotFinished)
                    }
                ];
                setPieCompletionData(respondentsCompletionArray);
                setPieTimeData(respondentsTimeArray);
            }

            // Respondents & their responses by Q
            const allRespondentsByQ = surveyReportProcessed.map((q) => {
                const responses = q?.responses;
                let respondentsArray = [];
                responses.forEach((answer) => {
                    const respondentId = answer.Recipient_UID;
                    const respondentArrayIndex = respondentsArray.findIndex(t => t?.respondent_id === respondentId);
                    if (respondentArrayIndex > -1) {
                        respondentsArray[respondentArrayIndex].responses.push({ response_id: answer.Response_ID });
                        respondentsArray[respondentArrayIndex].responses_count++;
                    } else {
                        respondentsArray.push({
                            respondent_id: respondentId,
                            survey_start_time: answer.Recipient_UID_Creation_timestamp,
                            survey_completion_time: answer.Recipient_UID_Survey_submission_timestamp,
                            responses_count: 1,
                            responses: [{
                                response_id: answer.Response_ID,
                            }]
                        });
                    }
                });
                return respondentsArray;
            });
            let respondentResponseAmount = [];
            allRespondentsByQ.flat().forEach(questionResponses => {
                let respondenseAmountIndex = respondentResponseAmount.findIndex(t => t?.responses_count === questionResponses.responses_count);
                if (respondenseAmountIndex > -1) {
                    respondentResponseAmount[respondenseAmountIndex].respondents++;
                } else {
                    respondentResponseAmount.push({
                        responses_count: questionResponses.responses_count,
                        respondents: 1,
                        label: `Respondents who gave ${questionResponses.responses_count} Responses`
                    });
                }
            })
            if (respondentResponseAmount.length) {
                setBarResponseAmountData(respondentResponseAmount);
                setBarResponseAmountHeight(respondentResponseAmount.length * 5);
            }

            // Demographics Data
            const demographicQuestions = surveyReportProcessed.filter((q) => Boolean(q.Is_Demographic_Question));
            const demographicQuestionData = demographicQuestions.map((question) => {
                let clonedQuestion = structuredClone(question);
                let pieQuestionData = [];
                clonedQuestion?.responses.forEach((response) => {
                    const answerId = response.Answer_UID;
                    const responseIDArrayIndex = pieQuestionData.findIndex(r => r?.answer_id === answerId);
                    if (responseIDArrayIndex > -1) {
                        pieQuestionData[responseIDArrayIndex].value++;
                    } else {
                        pieQuestionData.push({
                            answer_id: answerId,
                            id: response.Answer_text,
                            label: response.Answer_text,
                            value: 1
                        });
                    }
                });
                clonedQuestion.pieData = pieQuestionData;
                return clonedQuestion;
            });
            if (demographicQuestionData) {
                setPieDemographicData(demographicQuestionData);
            }

            const demographicCompletionData = demographicQuestions.map((question) => {
                let clonedQuestion = structuredClone(question);
                let surveyCompletedData = { name: "Completed", children: [] };
                let surveyNotCompleteData = { name: "Unfinished", children: [] };
                clonedQuestion?.responses.forEach((response) => {
                    const answerId = response.Answer_UID;
                    const surveyCompleted = Boolean(response.Recipient_UID_Survey_submission_timestamp);
                    if (surveyCompleted) {
                        const responseIDArrayIndex = surveyCompletedData.children.findIndex(r => r?.answer_id === answerId);
                        if (responseIDArrayIndex > -1) {
                            surveyCompletedData.children[responseIDArrayIndex].responses++;
                        } else {
                            surveyCompletedData.children.push({
                                answer_id: answerId,
                                name: response.Answer_text + ' ',
                                responses: 1
                            });
                        }
                    } else {
                        const responseIDArrayIndex = surveyNotCompleteData.children.findIndex(r => r?.answer_id === answerId);
                        if (responseIDArrayIndex > -1) {
                            surveyNotCompleteData.children[responseIDArrayIndex].responses++;
                        } else {
                            surveyNotCompleteData.children.push({
                                answer_id: answerId,
                                name: response.Answer_text,
                                responses: 1
                            });
                        }
                    }
                });
                const questionDataObject = {
                    name: clonedQuestion.Question,
                    children: [surveyCompletedData, surveyNotCompleteData]
                }
                return questionDataObject;
            });
            if (demographicCompletionData?.length > 0) {
                setSunburstDemographicCompletionData(demographicCompletionData);
            }

            // Responses by theme per Q
            let themeAnswersKeys = [];
            let themeResponseArray = [];
            const allResponsesbyThemePerQ = surveyReportProcessed.map((q) => {
                const responses = q?.responses;
                let questionThemeResponsesArray = [];
                responses.forEach((response) => {
                    if (response?.themes?.length) {
                        response.themes.forEach((theme) => {
                            const responseThemeIndex = themeResponseArray.findIndex(t => t?.id === theme.theme_id);
                            const questionResponseThemeIndex = questionThemeResponsesArray.findIndex(t => t?.theme_id === theme.theme_id);
                            if (responseThemeIndex > -1) {
                                themeResponseArray[responseThemeIndex].responses_count++;
                            } else {
                                themeResponseArray.push({
                                    id: theme.theme_id,
                                    name: theme.theme_name,
                                    responses_count: 1
                                });
                            }
                            if (questionResponseThemeIndex > -1) {
                                questionThemeResponsesArray[questionResponseThemeIndex].responses_count++;
                            } else {
                                questionThemeResponsesArray.push({
                                    theme_id: theme.theme_id,
                                    theme_name: theme.theme_name,
                                    responses_count: 1
                                });
                            }
                            const themeKey = themeAnswersKeys.find(t => t === theme.theme_name);
                            if (!themeKey) {
                                themeAnswersKeys.push(theme.theme_name)
                            }
                        })
                    }
                });
                let questionThemes = {
                    question_name: q.Question,
                    question_position: q.Question_Position,
                    themes: questionThemeResponsesArray,
                    theme_count: 0
                }
                questionThemeResponsesArray.forEach(theme => {
                    const theme_name = theme.theme_name;
                    if (questionThemes[theme_name]) {
                        questionThemes[theme_name].theme_count++;
                    } else {
                        questionThemes[theme_name] = theme.responses_count;
                    }
                })
                return questionThemes;
            });

            const allResponsesbyThemePerPerQSorted = allResponsesbyThemePerQ.sort((a, b) => b.question_position - a.question_position);
            let topThemes = undefined;
            let longestThemeLength = 0;
            if (themeResponseArray.length) {
                topThemes = themeResponseArray.sort((a, b) => b.responses_count - a.responses_count).slice(0, 3);
                longestThemeLength = themeResponseArray.reduce(
                    function (a, b) {
                        return a.name.length > b.name.length ? a : b;
                    }
                );
            }
            if (themeResponseArray.length) {
                if (topThemes) {setTopThemesInsight(topThemes)};
                setBarThemesResponsesData(themeResponseArray);
                setBarThemesResponsesHeight(themeResponseArray.length * 8);
                setBarThemesResponsesLeftMargin(longestThemeLength.name.length * 8);
            }
            if (allResponsesbyThemePerPerQSorted && themeAnswersKeys) {
                setBarThemesQuestionsData(allResponsesbyThemePerPerQSorted);
                setBarThemesQuestionsDataKeys(themeAnswersKeys);
                setBarThemesQuestionsHeight(allResponsesbyThemePerPerQSorted.length * 5);
            }
        }
    }, [rawSurveyReport, survey.end_date, survey.state]);

    useEffect(() => {
        const themeData = structuredClone(themesSurveyReport);
        if (themeData) {
            let themesAnswersArray = [];
            themeData.report.forEach((answer) => {
                const answerThemes = answer.themes;
                if (Object.keys(answerThemes).length) {
                    for (const property in answerThemes) {
                        let themesAnswerIndex = themesAnswersArray.findIndex(t => t?.id === property);
                        if (themesAnswerIndex > -1) {
                            themesAnswersArray[themesAnswerIndex]?.answers.push(answer.answer);
                            themesAnswersArray[themesAnswerIndex].answers_count++;
                        } else {
                            themesAnswersArray.push({
                                id: property,
                                name: answerThemes[property],
                                answers: [answer.answer],
                                answers_count: 1
                            });
                        }
                    }
                }
            });
            let longestThemeLength = 0;
            if (themesAnswersArray.length) {
                longestThemeLength = themesAnswersArray.reduce(
                    function (a, b) {
                        return a.name.length > b.name.length ? a : b;
                    }
                );
            }
            if (themesAnswersArray && (longestThemeLength > 0)) {
                setBarThemesAnswersHeight(themesAnswersArray.length * 4);
                setBarThemesAnswersLeftMargin(longestThemeLength.name.length * 2);
                setBarThemesAnswersData(themesAnswersArray);
            }
        }
    }, [themesSurveyReport]);

    useEffect(() => {
        if (keyphrasesSentiment) {
            console.log("keyphrases:")
            console.log(keyphrasesSentiment)
        }
        if (questionResponsesSentiment) {
            console.log("questionResponsesSentiment:")
            console.log(questionResponsesSentiment)
        }
        if (respondentsResponsesSentiment) {
            console.log("respondentsResponsesSentiment:")
            console.log(respondentsResponsesSentiment)
        }
        if (keyphraseBySentiment) {
            console.log("keyphraseBySentiment:")
            console.log(keyphraseBySentiment)
        }
        if (sentimentByKeyphrase) {
            console.log("sentimentByKeyphrase:")
            console.log(sentimentByKeyphrase)
        }
        if (questionTotalSentimentData) {
            console.log("questionTotalSentimentData:")
            console.log(questionTotalSentimentData)
        }
    }, [keyphrasesSentiment, questionResponsesSentiment, respondentsResponsesSentiment, keyphraseBySentiment, 
        sentimentByKeyphrase, questionTotalSentimentData
    ]);

    const csvDownloadHandler = async (type) => {
        const reportTypes = ['dump', 'summary', 'raw', 'themes'];
        if (!(reportTypes.includes(type))) {
            toast.error('Invalid Report Format!');
            return;
        }
        const csvDownloadToast = toast.loading('Downloading CSV...');
        const fileName = `${survey.name}_${type}_report_${dayjs().format('YYYYMMDD-HHmm')}.csv`;
        const response = fetcherFile(
            `/v2/survey/${surveyId}/report/${type}.csv`, 'GET', fileName
        );
        if (response && !response.error) {
            toast.update(csvDownloadToast, {
                render: 'CSV Downloaded', type: 'success', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        } else if (response && response.error) {
            toast.update(csvDownloadToast, {
                render: `Error Downloading CSV: ${response.error}`, type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        } else if (response && response.errors) {
            response.errors.map((error) => {
                toast.update(csvDownloadToast, {
                    render: `Error Downloading CSV: ${error}`, type: 'error', isLoading: false, autoClose: 5000,
                    pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
                });
                return error;
            });
        } else {
            toast.update(csvDownloadToast, {
                render: 'Error Downloading CSV', type: 'error', isLoading: false, autoClose: 5000,
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true
            });
        }
    };

    return (
<>
<TabPanel value={tabValue} className=''>
    <div className="">
        <Typography variant="h2" className="text-blue-gray-900 font-display p-2 rounded-lg bg-blue-gray-200 mt-2 pl-2 shadow w-full">
            Survey Insights, Charts and Tables, Data Downloads
        </Typography>
        <div className='rounded-lg mt-2'>
            <Typography className='flex justify-end'>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-200 
                        hover:text-white
                        text-blue-gray-900
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseMainInfo(collapseMainInfo => !collapseMainInfo)}>
                    Show/Hide Explanation
                </Button>
            </Typography>
            <Collapse open={collapseMainInfo}>
                <Card className='text-blue-gray-900 text-lg shadow-lg border border-grey-100 mx-4 my-2'>
                    <CardBody className=''>
                        <Typography className='font-body text-blue-gray-900 mt-4'>
                            The Reports section of your survey is split into several sections that will give you a greater understanding of some
                            of the data that has been collected. It is not a comprehensive analysis but a starting point for a deeper understanding.<br /><br />
                            The charts are live from the moment the survey is launched and will update automatically. If you have enabled Amazon
                            Comprehend to help with your manual Theming in Theme Manager it will automatically update as the survey is completed
                            by your survey recipients. Don't worry if you started your survey without enabling Amazon Comprehend's natural language processing as 
                            you can send your survey to this service after you have closed it.<br /><br />
                            Note that uncompleted surveys and partially completed questions are normally added to some insights and charts, especially
                            for processing using ML and NLP at the end of the survey once it has been closed.
                        </Typography>
                        <Typography className='font-body text-blue-900 pt-6 pl-6'>
                            &bull; 'Insights' is a quick overview of the most pertinent information we can determine from your survey. This is presented in a 
                            narrative format with some automatically derived statistics.<br /><br />
                            &bull; Charts are split into 'General Data', 'Themes Data' and 'Demographic Data'. 
                            Charts are live views of the data collected in your survey results. Use the selection box at the top of the 'Demographic Data' section
                            to change which question you see the results for (note that this only applies to surveys with multiple questionsthat are marked as demographic data).<br /><br />
                            &bull; The data downloads are the raw data available in a selection of output variations. This data is a direct snapshot of what is in the survey database at the time of pressing the button.
                            All data is collated and presented in CSV format.<br /><br />
                            &bull; The sections on 'Sentiment Analysis', 'Respondents Sentiment', 'Keyphrases and Keyphrase Sentiment' and 'Sentiment by Question'; display 
                            information returned when Amazon Comprehend has been selected in the survey settings. Or when the data is sent to Amazon Comprehend when a survey
                            is completed. This data will allow an insight into the sentiment of the language used, as well as pulling out keyphrases.<br />
                            The sentiment is given a score to indicate the percentage of confidence (the higher the percentage the more likely the sentiment is used). Further 
                            breakdowns have been provided to show the sentiment displayed by an individual survey respondent and the number of each type of sentiment used 
                            by question; these allow some insight into the general sentiment bias of each.
                            <br />
                        </Typography>
                    </CardBody>
                </Card>
            </Collapse>
        </div>

        <Card className="bg-white my-4 rounded-xl p-0 shadow">
            <CardHeader 
                floated={false}
                shadow={false}
                className="w-full bg-blue-gray-200 rounded-t-xl
                    rounded-b-none px-2 py-1 justify-left m-0">
                <Typography variant="h4" className="text-blue-gray-900 font-display p-2">
                    Survey Insights
                </Typography>
            </CardHeader>
            <CardBody className='font-body text-black px-2 p-2'>
                <div className='font-body text-md text-black text-left md:px-10 leading-10'>
                    <Typography className='font-body mt-4'>
                    Your survey has had a total of 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>{completionData?.total_respondents || 0} visitors</span>. &nbsp;
                    Of those {completionData?.total_respondents || 0} there were 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>{completionData?.respondents_finished || 0} visitors</span>
                    &nbsp;who finished the survey answering all the relevant questions, and 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>{(completionData?.total_respondents - completionData?.respondents_finished) || 0} visitors</span>
                    &nbsp;who left it uncompleted.
                    &nbsp;This means that
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>{((completionData?.respondents_finished / completionData?.total_respondents) * 100 || 0).toFixed(2)}%</span>
                    &nbsp;of people responded to all the survey questions.<br/>
                    </Typography>
                    
                    <Typography className='font-body mt-4'>
                    The top three themes in this survey were:&nbsp;
                    <span className='px-2 rounded-full bg-blue-gray-100'>{topThemesInsight?.[0]?.name || 'N/A'}</span>&nbsp;:&nbsp;
                    <span className='px-2 rounded-full bg-blue-gray-100'>{topThemesInsight?.[1]?.name || 'N/A'}</span>&nbsp;:&nbsp;
                    <span className='px-2 rounded-full bg-blue-gray-100'>{topThemesInsight?.[2]?.name || 'N/A'}</span>.
                    </Typography>

                    <Typography className='font-body mt-4'>
                    The most popular Gobby question on your survey was Question 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT NUMBER]</span>: 
                    &nbsp;"[QUESTION TEXT]". 
                    This had an average
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[MEAN AVG]</span> 
                    &nbsp;responses per person, this is the mean average which is all answers divided by the total number of visitors. 
                    &nbsp;The Mode average, which is the most common number of responses, was 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[MODE AVG]</span>.
                    &nbsp;The fewest number of responses was 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[MIN #]</span>,
                    &nbsp;and the highest number was 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[MAX #] responses</span>). 
                    &nbsp;The average (mean) number of responses for all the Gobby questions in your survey was 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[SURVEY AVG RESPONSES] answers</span>
                    &nbsp;per visitor.
                    </Typography>

                    <Typography className='font-body mt-4'>
                    The sentiment analysis on your survey revealed that
                    &nbsp;"[GOBBY QUESTION TEXT]"
                    &nbsp;had a broadly 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT AVG SENTIMENT ACROSS RESPONSES] Sentiment</span>
                    &nbsp;with an average of 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT PERCENTAGE]</span>. 
                    &nbsp;The whole survey sentiment breakdown was: 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT AVERAGE SENTIMENTS positive/negative/mixed/neutral]</span>.
                    </Typography>

                    <Typography className='font-body mt-4'>
                    The analysis for all the responses on your survey revealed that the average response sentiment was 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT MOST POPULAR SENTIMENT by AVG of ALL]</span> 
                    &nbsp;which was 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT PERCENTAGE][INSERT SENTIMENT]</span>. 
                    &nbsp;The breakdown of sentiment shows: 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] positive</span> sentiment; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] negative</span> sentiment; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] mixed</span> sentiment; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] neutral</span> sentiment.
                    </Typography>

                    <Typography className='font-body mt-4'>
                    Your visitors were mostly 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT MOST COMMON SENTIMENT OF RESPONDENTS]</span>.
                    &nbsp;There were:
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT NUMBER]</span> positive responses; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT NUMBER]</span> negative responses; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT NUMBER]</span> mixed responses; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT NUMBER]</span> neutral responses.
                    </Typography>

                    <Typography className='font-body mt-4'>
                    &nbsp;The most common keyphrases were 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[1st MOST COMMON KEYPHRASES]</span>;
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[2nd MOST COMMON KEYPHRASES]</span>;
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[3rd MOST COMMON KEYPHRASES]</span>;
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[4th MOST COMMON KEYPHRASES]</span>;
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[5th MOST COMMON KEYPHRASES]</span>.
                    </Typography>

                    <Typography className='font-body mt-4'> 
                    Overall the keyphrases were broadly 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT COMMON SENTIMENT]</span> 
                    &nbsp;in the sentiment breakdown. There were 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[INSERT NUMBER]</span> 
                    &nbsp;keyphrases found, the full sentiment breakdown of keyphrases shows: 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] positive</span> sentiment; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] negative</span> sentiment; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] mixed</span> sentiment; 
                    &nbsp;<span className='px-2 rounded-full bg-blue-gray-100'>[AVG PERCENTAGE] neutral</span> sentiment.
                    </Typography>

                </div>
                {/* Data is currently unavailable */}
                {/* <Typography className='font-body text-lg text-black w-full border border-gray-700 rounded-2xl p-4 my-4 text-center bg-white leading-loose'>
                    The most popular answer in the survey was:<br />
                    <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-200 font-bold'>!BEST RESPONSE!</span><br />
                    It was given to question: !QUESTIONNumber!: !QUESTION!
                </Typography> */}
                {/* Needs figuring out */}
                {/* <Typography className='font-body text-lg text-black w-full border border-gray-700 rounded-2xl p-4 my-4 text-center bg-white leading-loose'>
                    The most common three answers, in descending order, to !QUESTIONNUMBER!: !DEMOGRAPHICQUESTION! were:<br />
                    <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-200 font-bold'>!DEMOGRAPHIC RESPONSE!</span><br />
                    <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-200 font-bold'>!DEMOGRAPHIC RESPONSE!</span><br />
                    <span className=' px-2 py-1 border border-blue-gray-700 rounded-lg bg-blue-gray-200 font-bold'>!DEMOGRAPHIC RESPONSE!</span>
                    </Typography> */}
            </CardBody>
        </Card>

        <Card className="bg-white my-4 rounded-xl p-0 shadow">
            <CardHeader 
                floated={false}
                shadow={false}
                className="w-full bg-blue-gray-200 rounded-t-xl
                    rounded-b-none px-2 py-1 justify-left m-0">
                <Typography variant="h4" className="text-blue-gray-900 font-display p-2">
                    General Survey Data: Charts and Tables
                </Typography>
        </CardHeader>
        
        <CardBody className='font-body text-black px-2 p-2'>
        <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                <Typography variant='h4' className='ml-2 text-blue-gray-900'>
                    Survey Completion
                </Typography>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-200 
                        hover:text-white
                        text-blue-gray-900
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseGenCompletion(collapseGenCompletion => !collapseGenCompletion)}>
                    Show/Hide Explanation
                </Button>
                <Collapse open={collapseGenCompletion}>
                    <Typography as='span' className='mt-2 ml-2'>
                        The following chart shows the number of respondents who completed the survey versus the ones who left the survey unfinished.
                        Highlight a bar with your mouse pointer (roll over with the mouse or press and hold with a touch screen) for more
                        information.
                    </Typography>
                </Collapse>
                {(!!pieCompletionData &&
                <div className='h-80 md:h-[600px] mb-10'>
                    <ResponsivePie
                        data={pieCompletionData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={1.5}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        colors={{ scheme: 'nivo' }}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    .5
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'Completed'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'Unfinished'
                                },
                                id: 'lines'
                            },
                        ]}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
                )}
            </div>

            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                <Typography variant='h4' className='ml-2 text-blue-gray-900'>
                    Time Spent on Survey
                </Typography>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-200 
                        hover:text-white
                        text-blue-gray-900
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseGenTimeSpent(collapseGenTimeSpent => !collapseGenTimeSpent)}>
                    Show/Hide Explanation
                </Button>
                <Collapse open={collapseGenTimeSpent}>
                    <Typography as='span' className='mt-2 ml-2'>
                        This chart shows the average time to complete a survey versus the average time an unfinished survey was left. 
                        All times are rounded to the nearest minute.
                        Highlight a bar with your mouse pointer (roll over or press and hold with a touch screen) for more
                        information.
                    </Typography>
                </Collapse>
                {(!!pieTimeData &&
                <div className='h-80 md:h-[600px] mb-10'>
                    <ResponsivePie
                        data={pieTimeData}
                        valueFormat={value => dayjs.duration(value).humanize()}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={1.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        colors={{ scheme: 'nivo' }}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        defs={[
                            {
                                "id": "squares",
                                "type": "patternSquares",
                                "size": 4,
                                "padding": 4,
                                "stagger": false,
                                "background": "#e2dcc6",
                                "color": "#ecebda"
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'Completed'
                                },
                                id: 'squares'
                            },
                            {
                                match: {
                                    id: 'Unfinished'
                                },
                                id: 'lines'
                            },
                        ]}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </div>
                )}
            </div>

            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                <Typography variant='h4' className='ml-2 text-blue-gray-900'>
                    Number of Responses by Question
                </Typography>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-200 
                        hover:text-white
                        text-blue-gray-900
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseGenNumResponses(collapseGenNumResponses => !collapseGenNumResponses)}>
                    Show/Hide Explanation
                </Button>
                <Collapse open={collapseGenNumResponses}>
                    <Typography as='span' className='mt-2 ml-2'>
                        This chart shows the number of responses given on each question. Some questions allow for multiple answers so the numbers will
                        often exceed the total number of survey respondents. The vertical axis shows the question position number, the horizontal axis
                        shows the number of responses. Highlight a bar with your mouse pointer (roll over with the mouse or press and hold with a touch screen) to see
                        the question text and more information.
                    </Typography>
                </Collapse>
                {(!!barQuestionsRespondedData &&
                <div className='mb-10' style={{height: `${barQuestionsRespondedHeight}rem`}}>
                    <ResponsiveBar
                        colorBy="indexValue"
                        data={barQuestionsRespondedData}
                        indexBy="question_position"
                        keys={['responses']}
                        layout='horizontal'
                        margin={{ top: 10, right: 10, bottom: 80, left: 80 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        borderColor={
                            {
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.5
                                    ]
                                ]
                            }
                            }
                        borderWidth={1}
                        borderRadius={4}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 3,
                            tickRotation: 0,
                            legend: 'Number of Responses',
                            legendPosition: 'middle',
                            legendOffset: 40,
                            truncateTickAt: 0,
                            format: e => Math.floor(e) === e && e
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Question Position (Number)',
                            legendPosition: 'middle',
                            legendOffset: -60,
                            truncateTickAt: 0
                        }}
                        tooltip={(bar) => {
                            return (
                                // See https://github.com/plouc/nivo/blob/master/packages/tooltip/src/BasicTooltip.tsx
                                // for the formatting
                                <BasicTooltip
                                id={bar.data.question_name}
                                value={bar.formattedValue}
                                color={bar.color}
                                enableChip
                                />
                            )
                        }}
                        role="application"
                        ariaLabel="Responses per Question Bar Chart"
                    />
                </div>
                )}
            </div>

            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                <Typography variant='h4' className='ml-2 text-blue-gray-900'>
                    Number  of Responses given for each Question
                </Typography>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-200 
                        hover:text-white
                        text-blue-gray-900
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseGenNumResponsesEach(collapseGenNumResponsesEach => !collapseGenNumResponsesEach)}>
                    Show/Hide Explanation
                </Button>
                <Collapse open={collapseGenNumResponsesEach}>
                        <Typography as='span' className='mt-2 ml-2'>
                            This chart shows the number of responses to each question. Gobby® allows for multiple responses to some questions,
                            especially the 'open-style' Gobby® question. The vertical axis shows the number of responses given, and the horizontal 
                            the number of times that response occurred. So if there was a single response, given 12 times it would appear as a 1 on 
                            the vertical axis and 12 on the horizontal, i.e. a single response was given 12 times.  Highlight a bar with your mouse pointer 
                            (roll over or press and hold with a touch screen) for more information.
                        </Typography>
                </Collapse>
                {(!!barResponseAmountData &&
                <div className='mb-10' style={{height: `${barResponseAmountHeight}rem`}}>
                    <ResponsiveBar
                        colorBy="indexValue"
                        data={barResponseAmountData}
                        indexBy="responses_count"
                        keys={['respondents']}
                        layout='horizontal'
                        margin={{ top: 10, right: 10, bottom: 80, left: 80 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        borderColor={
                            {
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.5
                                    ]
                                ]
                            }
                            }
                        borderWidth={1}
                        borderRadius={4}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 3,
                            tickRotation: 0,
                            legend: 'Number of Respondents',
                            legendPosition: 'middle',
                            legendOffset: 40,
                            format: e => (Number.isInteger(e) ? e : ''),
                            truncateTickAt: 0
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Number of Responses',
                            legendPosition: 'middle',
                            legendOffset: -60,
                            truncateTickAt: 0
                        }}
                        tooltip={(bar) => {
                            return (
                                <BasicTooltip
                                    id={bar.data.label}
                                    value={bar.formattedValue}
                                    color={bar.color}
                                    enableChip
                                />
                            )
                        }}
                        role="application"
                        ariaLabel="Responses per Question Bar Chart"
                    />
                </div>
                )}
            </div>

        </div>
        </CardBody>
        </Card>

        <Card className="bg-white my-4 rounded-xl p-0 shadow">
            <CardHeader 
                floated={false}
                shadow={false}
                className="w-full bg-blue-gray-200 rounded-t-xl
                    rounded-b-none px-2 py-1 justify-left m-0">
                <Typography variant="h4" className="text-blue-gray-900 font-display p-2">
                    Themes: Charts and Tables
                </Typography>
            </CardHeader>
            
            <CardBody className='font-body text-black px-2 p-2'>
        <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                    <Typography
                        variant='h4'
                        className='ml-2 text-blue-gray-900 w-2/3'>
                            Number of responses by Theme
                    </Typography>
                <Button
                    type="button"
                    variant="filled"
                    size="lg"
                    className="
                        hover:bg-blue-gray-500 
                        bg-blue-gray-200 
                        hover:text-white
                        text-blue-gray-900
                        font-body
                        normal-case
                        px-4
                        py-2
                        rounded-full
                        mt-2
                        "
                    onClick={() => setCollapseThemesNumResponses(collapseThemesNumResponses => !collapseThemesNumResponses)}>
                    Show/Hide Explanation
                </Button>
                <Collapse open={collapseThemesNumResponses}>
                <Typography as="span" className='mb-6 ml-2'>
                    A count of themes on the responses to answers.
                    The vertical axis shows the theme name and the horizonal axis the number of times that theme has been
                    applied to a response, from any question. This chart quickly views the dominant themes in your survey.
                    Highlight a bar with your pointer (roll over with the mouse or press and hold with a touch screen) for more
                    information.
                </Typography>
            </Collapse>
            
            {( !!(barThemesResponsesData.length && barThemesResponsesHeight && barThemesResponsesLeftMargin) &&
                    <div className='mb-10' style={{height: `${barThemesResponsesHeight}rem`}}>
                    <ResponsiveBar
                        colorBy="indexValue"
                        data={barThemesResponsesData}
                        indexBy="name"
                        keys={[`responses_count`]}
                        layout='horizontal'
                        margin={{ top: 10, right: 10, bottom: 80, left: barThemesResponsesLeftMargin }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'nivo' }}
                        borderColor={
                            {
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.5
                                    ]
                                ]
                            }
                            }
                        borderWidth={1}
                        borderRadius={4}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 3,
                            tickRotation: 0,
                            legend: 'Number of Responses',
                            legendPosition: 'middle',
                            legendOffset: 40,
                            truncateTickAt: 0
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Theme',
                            legendPosition: 'middle',
                            legendOffset: -180,
                            truncateTickAt: 0
                        }}
                        tooltip={(bar) => {
                            return (
                                <BasicTooltip
                                id={bar.data.name}
                                value={bar.formattedValue}
                                color={bar.color}
                                enableChip
                                />
                            )
                        }}
                        role="application"
                        ariaLabel="Responses per Theme Bar Chart"
                    />
                </div>
                )}
            </div>

            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                <Typography
                        variant='h4'
                        className='ml-2 text-blue-gray-900'>
                            Number of Answers that share a Theme
                    </Typography>
                    <Button
                        type="button"
                        variant="filled"
                        size="lg"
                        className="
                            hover:bg-blue-gray-500 
                            bg-blue-gray-200 
                            hover:text-white
                            text-blue-gray-900
                            font-body
                            normal-case
                            px-4
                            py-2
                            rounded-full
                            mt-2
                            "
                        onClick={() => setCollapseThemesNumAnswers(collapseThemesNumAnswers => !collapseThemesNumAnswers)}>
                        Show/Hide Explanation
                    </Button>
                <Collapse open={collapseThemesNumAnswers}>
                    <Typography as="span" className='mb-6 ml-2'>
                        A count of the number of times a theme has been applied to an answer, not based on the number of responses
                        that theme has been applied to. The vertical axis shows the theme, the horizontal axis the number of
                        distinct answers that have the theme.
                        Highlight a bar with your mouse pointer (roll over with the mouse or press and hold with a touch screen) for more
                        information.
                    </Typography>
                </Collapse>
                {( !!(barThemesAnswersData.length && barThemesAnswersHeight && barThemesAnswersLeftMargin) &&
                <div className='mb-10' style={{height: `${barThemesAnswersHeight}rem`}}>
                <ResponsiveBar
                    colorBy="indexValue"
                    data={barThemesAnswersData}
                    indexBy="name"
                    keys={[`answers_count`]}
                    layout='horizontal'
                    margin={{ top: 10, right: 10, bottom: 80, left: barThemesAnswersLeftMargin }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'nivo' }}
                    borderColor={
                        {
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.5
                                ]
                            ]
                        }
                    }
                    borderWidth={1}
                    borderRadius={4}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Number of Answers',
                        legendPosition: 'middle',
                        legendOffset: 40,
                        truncateTickAt: 0
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Theme',
                        legendPosition: 'middle',
                        legendOffset: -180,
                        truncateTickAt: 0
                    }}
                    tooltip={(bar) => {
                        return (
                            <BasicTooltip
                            id={bar.data.name}
                            value={bar.formattedValue}
                            color={bar.color}
                            enableChip
                            />
                        )
                    }}
                    role="application"
                    ariaLabel="Answers per Theme Bar Chart"
                    />
                </div>
                )}
            </div>

            <div className="p-2 rounded-lg bg-white border mt-6 shadow w-full">
                    <Typography
                        variant='h4'
                        className='ml-2 text-blue-gray-900'>
                            Number of Responses by Theme per Question
                    </Typography>
                    <Button
                        type="button"
                        variant="filled"
                        size="lg"
                        className="
                            hover:bg-blue-gray-500 
                            bg-blue-gray-200 
                            hover:text-white
                            text-blue-gray-900
                            font-body
                            normal-case
                            px-4
                            py-2
                            rounded-full
                            mt-2
                            "
                        onClick={() => setCollapseThemesNumResponsesQ(collapseThemesNumResponsesQ => !collapseThemesNumResponsesQ)}>
                        Show/Hide Explanation
                    </Button>
                <Collapse open={collapseThemesNumResponsesQ}>
                    <Typography as="span" className='mb-6 ml-2'>
                        This is a stacked chart showing two types of information in each bar. The vertical axis
                        shows the question position in the survey as a number, the horizontal position shows the 
                        total number of theme responses. The individual boxes in the stack on the bar show a count of an individual theme. 
                        To see the theme name and the question detail simply highlight a bar with your pointer (roll over with the
                        mouse or press and hold with a touch screen), and this will show more information.
                    </Typography>
                </Collapse>
                {( !!(barThemesQuestionsData.length && barThemesQuestionsDataKeys.length && barThemesQuestionsHeight) &&
                <>
                    <div className="mb-10" style={{height: `${barThemesQuestionsHeight}rem`}}>
                        <ResponsiveBar
                            data={barThemesQuestionsData}
                            indexBy="question_position"
                            keys={barThemesQuestionsDataKeys}
                            layout='horizontal'
                            margin={{ top: 10, right: 10, bottom: 80, left: 80 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'nivo' }}
                            borderColor={
                                {
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.5
                                        ]
                                    ]
                                }
                            }
                            borderWidth={1}
                            borderRadius={2}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 3,
                                tickRotation: 0,
                                legend: 'Number of responses by theme',
                                legendPosition: 'middle',
                                legendOffset: 40,
                                truncateTickAt: 0
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Question Position (Number)',
                                legendPosition: 'middle',
                                legendOffset: -60,
                                truncateTickAt: 0
                            }}
                            tooltip={(bar) => {
                                return (
                                    // See https://github.com/plouc/nivo/blob/master/packages/tooltip/src/BasicTooltip.tsx
                                    // for the formatting
                                    <BasicTooltip
                                        id={`${bar.data.question_name}`}
                                        value={bar.id}
                                        color={bar.color}
                                        enableChip
                                    />
                                )
                            }}
                            role="application"
                            ariaLabel="Responses per Question Bar Chart"
                        />
                    </div>
                </>
                )}
            </div>
        </div>
        </CardBody>
        </Card>

        <Card className="bg-white my-4 rounded-xl p-0 shadow">
            <CardHeader 
                floated={false}
                shadow={false}
                className="w-full bg-blue-gray-200 rounded-t-xl
                    rounded-b-none px-2 py-1 justify-left m-0">
                <Typography variant="h4" className="text-blue-gray-900 font-display p-2">
                    Demographic Answer: Charts and Tables
                </Typography>
            </CardHeader>

            <CardBody className='font-body text-black px-2 p-2'>            
            <div className="p-2 rounded-lg bg-white border mb-2 shadow w-full">
            <Typography as='span' className='my-2 ml-2'>
                            Select A Demographic Question
            </Typography>
                {(!!(pieDemographicData && pieDemographicData?.length > 0) &&
                <>
                <Select label="Select a question to show demographic charts" value={demographicQShown.toString()} onChange={(e) => {
                    const indexInt = parseInt(e);
                    setDemographicQShown(indexInt);
                }}>
                    {pieDemographicData.map((q, index) => (
                    <Option key={q.Qst_UID} value={`${index}`}>Question {q.Question_Position}: {q.Question}</Option>
                    ))}
                </Select>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4'>
                <div className='h-80 md:h-[700px] mb-10'>
                    <ResponsivePie
                        data={pieDemographicData?.[demographicQShown]?.pieData}
                        margin={{ top: 20, right: 60, bottom: 20, left: 60 }}
                        innerRadius={0.3}
                        padAngle={0}
                        cornerRadius={3}
                        activeOuterRadiusOffset={6}
                        borderWidth={3}
                        animate={false}
                        colors={{ scheme: 'nivo' }}
                        borderColor="#fff"
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={3}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={5}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 30,
                                itemsSpacing: 4,
                                itemWidth: 60,
                                itemHeight: 100,
                                itemTextColor: '#333',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 15,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#06f',
                                            symbolSize: 30,
                                        }
                                    }
                                ]
                            }
                        ]}
                        />
                    </div>
                    {(!!(sunburstDemographicCompletionData.length > 0) &&
                    <>
                    <div className='h-80 md:h-[700px] mb-10'>
                        <ResponsiveSunburst
                            data={sunburstDemographicCompletionData[demographicQShown]}
                            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                            id='name'
                            value='responses'
                            cornerRadius={2}
                            borderWidth={3}
                            borderColor="#fff"
                            colors={{ scheme: 'nivo' }}
                            inheritColorFromParent={false}
                            childColor={{ theme: 'labels.text.fill' }}
                            enableArcLabels={true}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.4
                                    ]
                                ]
                            }}
                            arcLabel='id'
                            tooltip={(sunburst) => {
                                return (
                                    // See https://github.com/plouc/nivo/blob/master/packages/tooltip/src/BasicTooltip.tsx
                                    // for the formatting
                                    <BasicTooltip
                                    id={sunburst.id}
                                    value={`${sunburst.value} - ${sunburst.formattedValue}`}
                                    color={sunburst.color}
                                    enableChip
                                    />
                                )
                            }}
                        />
                    </div>
                    </>
                    )}
                    </div>
                    </>
                    )}
            </div>

            </CardBody>
        </Card>




        <Card className="bg-white mt-2 rounded-xl p-0 shadow min-h-[90px]">
            <CardHeader 
                floated={false}
                shadow={false}
                className="w-full bg-blue-gray-200 rounded-t-xl
                    rounded-b-none px-2 py-1 justify-left m-0">
                    <Typography variant="h4" className="text-blue-gray-900 font-display p-2">
                        Raw Data Downloads
                    </Typography>
            </CardHeader>
            <CardBody className='font-body text-black px-2 p-2'>
                <div className='p-2 mb-2'>
                    <Typography as="div" className="font-body text-blue-gray-900">
                        These downloads provide the option for manual analysis and reporting of survey data. There are three summary report file downloads:​<br />
                        1. By Respondent​;<br />
                        2. By Question​;<br />
                        3. By Theme​.<br /><br />
                        You can also download All Data for in depth analysis and reporting. If you need help to manually analyse this data and represent what respondents had to say, 
                        &nbsp;contact us for some friendly support options.
                    </Typography>
                    <Typography className="mt-4">
                        <Button
                            type="button"
                            variant="filled"
                            color="white"
                            size="lg"
                            className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                            onClick={() => csvDownloadHandler('dump')}>
                            Response Data
                        </Button>
                        <Button
                            type="button"
                            variant="filled"
                            color="white"
                            size="lg"
                            className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                            onClick={() => csvDownloadHandler('summary')}>
                            Answer Summary
                        </Button>
                        <Button
                            type="button"
                            variant="filled"
                            color="white"
                            size="lg"
                            className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                            onClick={() => csvDownloadHandler('themes')}>
                            Themes
                        </Button>
                        <Button
                            type="button"
                            variant="filled"
                            color="white"
                            size="lg"
                            className="mb-2 rounded-full py-1 px-4 font-body font-thin text-lg normal-case hover:border-1 hover:border-blue-500 hover:text-blue-500 border border-gray-700 mr-4"
                            onClick={() => csvDownloadHandler('raw')}>
                            All Data
                        </Button>
                    </Typography>
                    <Typography as="div" className="font-body mt-4 text-sm text-blue-gray-900">
                        (Note: Downloads the result of the survey in CSV Format, choose a report layout to download. Please note that an 'All Data' download is a very large 
                        file containing all data in a long data format of one response per line. (multiple responses of the same type *will* appear on multiple lines).)
                    </Typography>
                </div>
            </CardBody>
        </Card>
    </div>
</TabPanel>
</>
    );
}
