import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, IconButton, Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import FormikRadio from 'components/form/formik-radio';
import FormikCheckbox from 'components/form/formik-checkbox';
import MaterialIcon from 'components/material-icon';
import * as Yup from 'yup';

export default function CustomResultsDialog(props) {
    const question = props.question;
    const responses = props.responses;
    const response = props.responses.custom;

    const handleMoreOptions = async (index) => {
    };

    const cancelCustomResults = async () => {
        props.close();
    };

    const handleCustomSubmit = async (values) => {
        props.saveSelection(values);
        props.close();
    };

    const reportAnswer = async (answerNanoid) => {
        props.setReportedAnswer(answerNanoid);
        props.setShowConfirmReportDialog(true);
        props.close();
    }

    const validationSchemaCustom =
          Yup.array().of(Yup.string().required());

    return (
        <>
            {((props.searchResults && props.searchResults.found > 0) &&
              <Dialog open={props.showDialog} handler={props.handle} className='overflow-visible min-w-fit'>
                  <DialogHeader className="bg-black text-white font-display rounded-t-lg">
                      Results Matching: {props.searchTerm || 'LOADING/UNKNOWN'}
                  </DialogHeader>
                  <DialogBody className="overflow-y-auto max-h-[80vh]">
                    <Typography className='font-body my-2 text-center text-blue-gray-900'>Choose one, or more, responses, or go back and add your own.</Typography>
                        <Formik initialValues={{
                                  answer: response.length === 0
                                      ? new Array(props.searchResults.hits.length)
                                      : ( question.answer_type === 'single'
                                          ? [response[0].nanoid]
                                          : props.searchResults.hits.map((p) => response.some((ele) => ele.nanoid === p.nanoid) ? p.nanoid : '')
                                        )
                              }}
                              onSubmit={handleCustomSubmit}
                              validationSchema={validationSchemaCustom}>
                          {({values, isValid, dirty}) => (
                              <Form>
                                    {props.searchResults.hits.map((result, index) => (
                                        <div className="flex font-body border-b border-gray-700 px-2" key={index}>
                                            <div className='font-body grow text-left mt-2'>
                                                {question.answer_type === 'single' &&
                                                <FormikRadio
                                                    id={`values.answer.0.nanoid`}
                                                    name={`answer.0`}
                                                    color="blue"
                                                    label={result.document.text}
                                                    value={result.document.answer_nanoid}
                                                    labelProps={{
                                                        className: "text-blue-gray-900",
                                                      }}
                                                />
                                                }
                                                {question.answer_type === 'multiple' &&
                                                <FormikCheckbox
                                                    id={`values.answer.${index}.nanoid`}
                                                    name={`answer`}
                                                    color="blue"
                                                    label={result.document.text}
                                                    value={result.document.answer_nanoid}
                                                    labelProps={{
                                                        className: "text-blue-gray-900",
                                                      }}                                                    
                                                />
                                                }
                                            </div>
                                            <div className='font-body text-right border-l border-gray-700 my-2'>
                                                <IconButton color="red"
                                                            type="button"
                                                            variant="text"
                                                            onClick={() => reportAnswer(result.document.answer_nanoid)}>
                                                    <MaterialIcon
                                                        name="flag"
                                                        size="2xl"/>
                                                </IconButton>    
                                            </div>
                                        </div>
                                    ))}
                                        <div className='font-body my-4 text-center text-blue-gray-900'>
                                            Click on the flag (<MaterialIcon color="red" name="flag" size="xl"/>) next to an answer to report any abuse.
                                        </div>
                            <DialogFooter className="">
                                <div className="">
                                      <Button   type="submit"
                                                color="white"
                                                className='
                                                    mr-4 
                                                    hover:border-1
                                                    hover:border-blue-500
                                                    hover:text-blue-500
                                                    border 
                                                    border-gray-700 
                                                    px-6 py-2 
                                                    rounded-full' 
                                                    variant="filled"
                                                    size="lg"
                                                >
                                          Confirm
                                      </Button>
                                      <Button   type="button"
                                                color="white"
                                                className='
                                                    mr-4 
                                                    hover:border-1
                                                    hover:border-blue-500
                                                    hover:text-blue-500
                                                    border 
                                                    border-gray-700 
                                                    px-6 py-2 
                                                    rounded-full' 
                                                    variant="filled"
                                                    size="lg"
                                                onClick={cancelCustomResults}
                                      >
                                          Cancel
                                      </Button>
                                  </div>
                                </DialogFooter>
                              </Form>
                          )}
                      </Formik>
                  </DialogBody>
              </Dialog>
             )}
        </>
    );
}

