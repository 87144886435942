import React from 'react';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography} from '@material-tailwind/react';

export default function RespondentResponseSentimentListDialog({showRespondentResponseSentimentListDialog, setShowRespondentResponseSentimentListDialog, 
    responseSentimentListId, setResponseSentimentListId, respondentSentimentData}) {

    const percentFormat = (decimal) => {
        let formattedPercent;
        if (Number.isFinite(decimal)) {
            formattedPercent = Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }).format(decimal);
        } else {
            formattedPercent = "N/A";
        }
        return formattedPercent;
    }
    
    return (
        <Dialog open={showRespondentResponseSentimentListDialog} 
        handler={() => setShowRespondentResponseSentimentListDialog(showRespondentResponseSentimentListDialog=>!showRespondentResponseSentimentListDialog)}>
            <DialogHeader className="bg-blue-gray-300 text-black font-display rounded-t-lg">
                Responses Sentiment
            </DialogHeader>
            <Typography className='px-4 pt-2 font-body'>A list of sentiment by individual response text with the response text ID.</Typography>
            <DialogBody className='font-body overflow-y-auto max-h-[60vh]'>
                <table className="table-auto w-full text-center border-b border-blue-gray-500">
                    <thead className='text-center text-lg border-b border-gray-500 text-blue-gray-900'>
                        <tr>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-900 rounded-t-lg w-1/12'>ID</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-900 rounded-t-lg w-7/12 text-left'>Response Text</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-900 rounded-t-lg w-1/12'>Positive</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-900 rounded-t-lg w-1/12'>Neutral</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-900 rounded-t-lg w-1/12'>Negative</th>
                            <th className='border-b-2 border-r-white border-r-4 border-blue-gray-500 bg-blue-gray-200 px-2 py-2 font-body text-blue-gray-900 rounded-t-lg w-1/12'>Mixed</th>
                        </tr>
                    </thead>
                    {(respondentSentimentData && 
                    <tbody className='text-blue-gray-900'>            
                        {respondentSentimentData?.[responseSentimentListId]?.responses_with_sentiment?.map((response, index) => (
                            <tr className='border-b border-gray-300 text-blue-gray-900 even:bg-blue-gray-50/50 text-right' key={response.response_id}>
                                <td className='text-center'>{response.response_id}</td>
                                <td className='text-left'>{response.text}</td>
                                <td>
                                    <span className={`${response.sentiment.positive_score === response.sentiment.score ?  'font-bold bg-green-200 rounded-full text-center border-white border border-2' : '' }`}>
                                        {percentFormat(response.sentiment.positive_score)}
                                    </span>
                                </td>
                                <td>
                                    <span className={`${response.sentiment.neutral_score === response.sentiment.score ?  'font-bold bg-green-200 rounded-full text-center border-white border border-2' : '' }`}>
                                        {percentFormat(response.sentiment.neutral_score)}
                                    </span>
                                </td>
                                <td>
                                    <span className={`${response.sentiment.negative_score === response.sentiment.score ?  'font-bold bg-green-200 rounded-full text-center border-white border border-2' : '' }`}>
                                        {percentFormat(response.sentiment.negative_score)}
                                    </span>
                                </td>
                                <td>
                                    <span className={`${response.sentiment.mixed_score === response.sentiment.score ?  'font-bold bg-green-200 rounded-full text-center border-white border border-2' : '' }`}>
                                        {percentFormat(response.sentiment.mixed_score)}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    )}
                </table>
            </DialogBody>
            <DialogFooter className="">
                <div className=''>
                    <Button type="button" 
                        color='white'
                        className='
                        mr-4 
                        hover:border-1
                        hover:border-blue-500
                        hover:text-blue-500
                        border 
                        border-gray-700 
                        px-6 py-2 
                        rounded-full' 
                        variant="filled"
                        size="lg" 
                        onClick={() => setShowRespondentResponseSentimentListDialog(false)}>
                        Close
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    )
}
