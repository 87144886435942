import React, {useEffect, useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter,
    Typography, Switch} from '@material-tailwind/react';
import FormikInput from '../form/formik-input';
import FormikTextarea from 'components/form/formik-textarea';

export default function QuestionSettingsDialog({showDialog, qI, handle}) {
    const {values, setFieldValue} = useFormikContext();
    const index = qI || 0;
    const [questionDescription, showQuestionDescription] = useState(false);
    const [questionDemographic, showQuestionDemographic] = useState(false);
    const [respondentsSeeOthers, showRespondentsSeeOthers] = useState(false);

    const updateStates = useCallback(() => {
        showQuestionDescription(values.questions?.[index]?.description ? true : false);
        showRespondentsSeeOthers(values.questions?.[index]?.is_view_all ? true : false);
        showQuestionDemographic(values.questions?.[index]?.is_demographic_question ? true : false);
    },[index, values.questions]);
    
    useEffect(() => {
        updateStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDialog]);

    const setQuestionType = (questionType) => {
        setFieldValue(`questions.${index}.question_type`, questionType);
    };

    const setAnswerType = (answerType) => {
        setFieldValue(`questions.${index}.answer_type`, answerType);
    };

    const handleCheckDescription = () => {
        if (questionDescription) {
            setFieldValue(`questions.${index}.description`, '');
            showQuestionDescription(false);
        } else {
            showQuestionDescription(true);
        }
    };

    const handleQuestionDemographic = () => {
        if (questionDemographic && values.questions?.[index]?.is_demographic_question) {
            setFieldValue(`questions.${index}.is_demographic_question`, false);
            showQuestionDemographic(false);
        } else {
            setFieldValue(`questions.${index}.is_demographic_question`, true);
            showQuestionDemographic(true);
        }
    };

    const handleCheckViewOthers = () => {
        if (respondentsSeeOthers && values.questions?.[index]?.is_view_all) {
            setFieldValue(`questions.${index}.is_view_all`, false);
            showRespondentsSeeOthers(false);
        } else {
            setFieldValue(`questions.${index}.is_view_all`, true);
            showRespondentsSeeOthers(true);
        }
    };


    return (

        <Dialog open={showDialog} handler={handle} className="rounded-xl overflow-visible min-w-fit" size="lg"         
        animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 1, y: -100 },
          }}>
            <DialogHeader className="g-gray-300 text-black font-display rounded-t-lg">
                Question Settings
            </DialogHeader>
            <DialogBody className='m-2 font-body overflow-y-auto max-h-[80vh]'>
                <div className="flex flex-col gap-2 flex-auto">
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div className='basis-4/6'>
                            <div className="mr-2">
                                Question Type
                            </div>
                            <Typography variant="small" color="blue-gray" className='font-body'>
                            This sets the basic question type. There are 3 variations. 1. Gobby&#8482; – Respondents write in their own words​
                            2. *Pre-set - Add one or more response options that people can choose. This is good for demographic style questions​.
                            3. *Mixed – Allows for both Pre-set and Gobby response options​. 
                            (Note: Pre-set and Mixed question types are excluded from post survey ‘Gobby AI Assist’ analysis”)
                            </Typography> 
                        </div>
                        
                        <div className="place-self-center text-center">
                            <Button color="blue-gray" type="button" className='hover:bg-blue-gray-300 hover:text-black hover:border-blue-gray-300 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.question_type === 'freeform' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => {setQuestionType('freeform')}}>
                                Gobby&#8482;
                            </Button>
                            &ensp;
                            <Button color="blue-gray" type="button" className='hover:bg-blue-gray-300 hover:text-black hover:border-blue-gray-300 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.question_type === 'mixed' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => {setQuestionType('mixed')}}>
                                Mixed
                            </Button>
                            &ensp;
                            <Button color="blue-gray" type="button" className='hover:bg-blue-gray-300 hover:text-black hover:border-blue-gray-300 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.question_type === 'fixed' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => {setQuestionType('fixed')}}>
                                Pre-set
                            </Button>

                        </div>
                    </div>
                    {((values.questions?.[index]?.question_type === 'mixed' ||values.questions?.[index]?.question_type === 'freeform') &&
                        <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                            <div className='basis-5/6'>
                                <div className="mr-2">
                                    Gobby&#8482; ‘matched’ responses​
                                </div>
                                <Typography variant="small" color="blue-gray" className='font-body'>
                                Allow ‘matched’ e.g., view and vote for relevant Gobby responses to their own, for a more interactive peer experience.​ Turn this option off where responses might include personally identifiable or highly sensitive information”​
                                </Typography>
                            </div>
                            <div className="place-self-center">
                                <Switch checked={respondentsSeeOthers} color="green" id="checkbox3" text=""
                                    onChange={() => handleCheckViewOthers()} />
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div>
                            <div className="mr-2">
                            Single or multiple responses​
                            </div>
                            <Typography variant="small" color="blue-gray" className='font-body'>
                            Allow respondents to add a Single or Multiple responses to this question.
                            </Typography>
                        </div>
                        <div className="place-self-center text-center">
                            <Button color="blue-gray" type="button" className='hover:bg-blue-gray-300 hover:text-black hover:border-blue-gray-300 mt-1 rounded-full'
                                variant={`${values.questions?.[index]?.answer_type === 'single' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => setAnswerType('single')}>
                                Single
                            </Button>
                            &ensp;
                            <Button color="blue-gray" type="button" className='hover:bg-blue-gray-300 hover:text-black hover:border-blue-gray-300 mt-1 rounded-full'
                            variant={`${values.questions?.[index]?.answer_type === 'multiple' ? 'filled' : 'outlined'}`}
                                size="sm" onClick={() => setAnswerType('multiple')}>
                                Multiple
                            </Button>

                        </div>
                    </div>
                    {((values.questions?.[index]?.question_type === 'mixed' ||values.questions?.[index]?.question_type === 'freeform') &&
                        <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                            <div>
                                <div className="mr-2">
                                Gobby&#8482; response character limit​
                                </div>
                                <Typography variant="small" color="blue-gray" className='font-body'>
                                Sets the character limit to a Gobby&#8482; response. A maximum of 200 characters can be applied. Where ‘Multiple’ responses are allowed, respondents can save more than one answer.
                                </Typography>
                            </div>
                            <div className="place-self-center border rounded border-gray-500 text-center">
                                <FormikInput className="p-2 font-body text-center" type="number" variant="static" size="md" color="orange" min="1" max="200" 
                                    name={`questions.${index}.answer_limit`} placeholder="enter a value" />characters
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div>
                            <div className="mr-2">
                            Add a question description​ (optional) 
                            </div>
                            <Typography variant="small" color="blue-gray" className='font-body'>
                            Add optional text directly underneath this question to support context and guidance for respondents.
                            </Typography>
                        </div>
                        <div className="place-self-center">
                            <Switch checked={questionDescription} color="green" id="checkbox4" text=""
                                onChange={() => handleCheckDescription()} />
                        </div>
                    </div>
                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div className='basis-5/6'>
                            <div className="mr-2">
                                Track as a Demographic question
                            </div>
                            <Typography variant="small" color="blue-gray" className='font-body'>
                                Use this toggle to track if the question data will be used for demographics in the
                                result data. Demographic questions tend to be ‘pre-set’ question types with a limited range of characteristics that 
                                respondents identify with i.e., roles, locations, age ranges etc.
                                This data will be used to report on the ‘representation’ of people that have completed the survey and can 
                                cross-referenced with their responses for in-depth analysis. 
                                Please ensure you have informed your respondents of this using the applicable data protection laws!
                            </Typography>
                        </div>
                        <div className="place-self-center">
                            <Switch checked={questionDemographic} color="green" id="checkbox5" text=""
                                onChange={() => handleQuestionDemographic()} />
                        </div>
                    </div>

                    <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-md">
                        <div className='basis-5/6'>
                            <div className="mr-2">
                                Question ontains personally Identifiable Information (!FIX ME!)
                            </div>
                            <Typography variant="small" color="blue-gray" className='font-body'>
                                Use this toggle to track if the question data will contain personally Identifiable Information (PII).
                                Please ensure you have informed your respondents of this using the applicable data protection laws!
                                PII information *will not* be sent to an external source for analysis. 
                            </Typography>
                        </div>
                        <div className="place-self-center"> !FIX ME!
                            <Switch checked={questionDemographic} color="green" id="checkbox5" text=""
                                onChange={() => handleQuestionDemographic()} />
                        </div>
                    </div>

                    {( questionDescription &&
                        <div className="flex justify-between border border-gray-400 p-2 border-1 rounded-ml">
                            <FormikTextarea name={`questions.${index}.description`} type="text" 
                            color="indigo" className="border border-gray-400 p-2 rounded-xl font-body" label='Question Description'/>
                        </div>
                    )}
                </div>
                <p></p>                             
            </DialogBody>
            
            <DialogFooter className='mt-6'>
                <div className='absolute bottom-4 right-4 '>
                    <Button type="button" variant="filled" color='white'
                    className="rounded-full py-1 px-6 font-body font-thin normal-case text-lg hover:border-1 hover:border-blue-500 hover:text-blue-500 border-gray-700 border" onClick={() => handle()}>
                        Apply
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    )
}
